import React from "react"

export default class signUpEmail extends React.Component {
  state = {
    email: "",
  };
  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    })
  };
  handleSubmit = event => {
    event.preventDefault();
    alert(`Welcome ${this.state.email}!`)
    // Send data to HubSpot here.
  };

  render() {
    return (
      <div className={"SignUpEmailComponentCss"}>
        <div className={"text-align-center"}>
          <h2>STAY UPDATED ON DIGITAL MARKETING TRENDS</h2>
        </div>
        <h3 className={"subtitle"}>Get The Latest Avantcy Updates Via Email And Stay Connected With All Digital
          Marketing Aspects. Sign With Us
          Today!</h3>
        <form onSubmit={this.handleSubmit}>
          <input
            type="email"
            name="email"
            placeholder={"Your Email Address"}
            value={this.state.email}
            onChange={this.handleInputChange}
          />
          <div className={"text-align-center"}>
            <button type="submit" className={"button-red"}>SUBMIT</button>
          </div>
        </form>
      </div>
    )
  }
}
