import React from "react"
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SignUpEmail from "../../components/signUpEmail";
import img1 from "../../images/contact/contact-img-1.svg";
import {InlineWidget} from "react-calendly";


const contactPage = () => (
  <Layout css="contactPageCss">
    <SEO title="Contact Us Today"
         description={"Looking to contact the best digital marketing company out there? Look no further. Contact Avantcy Marketing now."}/>
    <div className={"container hero section-padding-wrapper"}>
      <div className={"row align-items-center m-flex-direction-col-rev"}>
        <div className={"col-md-6"}>
          <h1>CONTACT <span className={"red"}>AVANCTY MARKETING</span> TODAY</h1>
          <div className={"subtitle"}>
            <p>We have shared a great deal of information so far. A careful study of our website will let you see that
              you are working with the best digital marketing agency. We have more than what we have displayed in the
              store for you when you get in touch.</p>
            <p>Do you have questions you need to be answered? Business proposals? Or do you want to see our portfolio
              for what to expect? Get in touch with our professional digital marketing company now.</p>
          </div>
        </div>
        <div className={"col-md-6"}>
          <img alt={""} src={img1} className={"w-100 m-pic-align"}/>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"text-align-center"}>
        <h2 id={"contact-today"}>WHAT CAN OUR PROFESSIONAL DIGITAL MARKETING AGENCY DO FOR YOU TODAY?</h2>
      </div>
      <h3 className={"subtitle"}>Let us know what you need. Among the best content marketing agencies. We offer numerous
        services you can call for today.</h3>
      <InlineWidget class={"bruhmoment"} url="https://calendly.com/avantcy-marketing-sam-i/contact-connect"
                    styles={{height: 'inherit'}}
                    pageSettings={{
                      hideEventTypeDetails: false,
                      hideLandingPageDetails: true,
                      primaryColor: '#c00000',
                      textColor: '#4d5055'
                    }}
                    utm={{
                      utmCampaign: 'Website Contact Page',
                      utmContent: 'Sign up links',
                      utmMedium: 'Calendly',
                      utmSource: 'Webpage'
                    }}
      />
    </div>
    {/*<div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <SignUpEmail/>
      </div>
    </div>*/}
  </Layout>
);

export default contactPage;
